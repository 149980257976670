import React from 'react';
import './styles/style.scss'

import Sections from './components/Sections';

import CanvasThreeFiber from './components/CanvasThreeFiber';
import Enter from './components/Enter';
import Loader from './components/Loader';


window.addEventListener(
  'contextmenu',
  function (e) {
    e.preventDefault();
  },
  false
);

function App() {
  return (
    <div className="App">
      {/* <Loader /> */}
      <Enter />
      <Sections />
      <CanvasThreeFiber />
    </div>
  );
}

export default App;
