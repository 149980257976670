import React, { useEffect, useRef, useState } from 'react';
/* import * as THREE from 'three'; */
import { extend } from '@react-three/fiber'
import { useFrame } from '@react-three/fiber';

import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { MeshPhongMaterial } from 'three/src/materials/MeshPhongMaterial'


/* import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { MeshPhongMaterial } from 'three' */

import myFont from '../assets/images/THREEJS/Roboto_Regular.json'

extend({ TextGeometry, FontLoader, MeshPhongMaterial })

const FontThreeJs = () => {

    const groupRef = useRef();

    /*    // State to hold mouse positions
       const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
   
       // Update mouse position state
       const handleMouseMove = (event) => {
           setMousePosition({
               x: (event.clientX / window.innerWidth) * 2 - 1,
               y: -(event.clientY / window.innerHeight) * 2 + 1
           });
       };
    */




    // Rotate group automatically
    useFrame(() => {
        groupRef.current.rotation.y += 0.0010;
        groupRef.current.rotation.x += 0.0010;
        groupRef.current.rotation.z += 0.0010;
    });


    // Rotate group based on mouse position

    /*   useFrame(() => {
          if (groupRef.current) {
              const { x, y } = mousePosition;
              groupRef.current.rotation.y = x * Math.PI;
              groupRef.current.rotation.x = y * Math.PI;
          }
      });
   */
    const font = new FontLoader().parse(myFont);

    const textOptions = {
        font,
        size: 1,
        height: 0.1,
        /* curveSegments: 12, */
        bevelEnabled: false
    };

    // Create a single instance of textGeometry
    const textGeometry = new TextGeometry('ff87c3', textOptions);

    const wordOpacity = 0.7;

    return (
        <>
            <group ref={groupRef} /* onMouseMove={handleMouseMove} */ >

                <mesh position={[-2, -0.5, 0.1]}>
                    <primitive object={textGeometry} />
                    <meshPhongMaterial /* color="#1ce809" */ transparent opacity={wordOpacity} />
                </mesh>

                <mesh position={[0, -2, 0.5]} rotation={[0, 2, Math.PI / 3]} > {/* pink */}
                    <primitive object={textGeometry} />
                    <meshPhongMaterial /* color="#ff87c3" */ transparent opacity={wordOpacity} />
                </mesh>

                <mesh position={[0.5, -2, -0.5]} rotation={[0, -2, Math.PI / 4]} >  {/* yellow */}
                    <primitive object={textGeometry} />
                    <meshPhongMaterial /* color="#FFFF00" */ transparent opacity={wordOpacity} />
                </mesh>

                <mesh position={[-1, -1.3, -1.5]} rotation={[0.2, -1, Math.PI / 6]} >  {/* red */}
                    <primitive object={textGeometry} />
                    <meshPhongMaterial /* color="#FF0000" */ transparent opacity={wordOpacity} />
                </mesh>


            </group>
        </>
    );
};

export default FontThreeJs;

{/* <mesh  position={[0.5, -1.9, 0]} rotation={[0, 0, Math.PI / 2]}  >  
<textGeometry attach="geometry" args={['ff87c3', textOptions]} />
<meshPhongMaterial  color="#000000"     shininess={0}  transparent opacity={wordOpacity} />
</mesh> */}