const imagesWebsite = {


    eurorue: {
        title: "eurorue",
        website: "https://www.eurorue.eu/",
        website02: "eurorue.eu/",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/eurorue/jpeg/eurorue-01.jpg",
                srcWebp: "../assets/images/websites/eurorue/webp/eurorue-01.webp",
                loading: "eager"
            },
        ]
    },

    ximon: {
        title: "Ximon Lee",
        website: "https://ximonlee.vercel.app/",
        website02: "ximonlee.com/",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/ximon_lee/jpeg/image05.jpg",
                srcWebp: "../assets/images/websites/ximon_lee/webp/image05.webp",
                loading: "eager"
            },
            /*    {
                   key: 2,
                   srcJpeg: "../assets/images/websites/ximon_lee/jpg/ximon_lee_02.jpg",
                   srcWebp: "../assets/images/websites/ximon_lee/webp/ximon_lee_02.webp",
                   loading: "eager"
               },
               {
                   key: 3,
                   srcJpeg: "../assets/images/websites/ximon_lee/jpg/ximon_lee_03.jpg",
                   srcWebp: "../assets/images/websites/ximon_lee/webp/ximon_lee_03.webp",
                   loading: "eager"
               }, */

        ]
    },

    ronald: {
        title: "Ronald Rose",
        website: "https://www.ronaldrose.eu/",
        website02: "ronaldrose.eu/",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/ronald_rose/jpeg/ronald_rose_07.jpg",
                srcWebp: "../assets/images/websites/ronald_rose/webp/ronald_rose_07.webp",
                loading: "eager"
            },
            /*    {
                   key: 2,
                   srcJpeg: "../assets/images/websites/ronald_rose/jpg/ronald_rose_02.jpg",
                   srcWebp: "../assets/images/websites/ronald_rose/webp/ronald_rose_02.webp",
                   loading: "eager"
               },
               {
                   key: 3,
                   srcJpeg: "../assets/images/websites/ronald_rose/jpg/ronald_rose_03.jpg",
                   srcWebp: "../assets/images/websites/ronald_rose/webp/ronald_rose_03.webp",
                   loading: "eager"
               },
    */


        ]
    },




    haiku: {
        title: "Haiku generator",
        website: "https://ai-ku-generator.vercel.app/",
        website02: "ai-ku-generator.vercel.app/",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/haiku_generator/jpeg/haiku_generator_02.jpg",
                srcWebp: "../assets/images/websites/haiku_generator/webp/haiku_generator_02.webp",
                loading: "eager"
            },


        ]
    },
    wordscrawler: {
        title: "words crawler",
        website: "https://words-crawler.vercel.app/",
        website02: "words-crawler.vercel.app/",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/words_crawler/words-crawler03.jpg",
                srcWebp: "../assets/images/websites/words_crawler/words-crawler03.jpg",
                loading: "eager"
            },


        ]
    },

    /*   wordsmaterializer: {
          title: "words materializer",
          website: "https://words-materializer.vercel.app/",
          website02: "words-materializer.vercel.app/",
          pictures: [
              {
                  key: 1,
                  srcJpeg: "../assets/images/websites/words-materializer/jpeg/words-materializer-01.jpg",
                  srcWebp: "../assets/images/websites/words-materializer/webp/words-materializer-01.webp",
                  loading: "eager"
              },
  
  
          ]
      }, */
    visualscrawler: {
        title: "visuals crawler",
        website: "https://visuals-crawler.vercel.app/",
        website02: "visuals-crawler.vercel.app/",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/visuals_crawler/jpeg/visuals-crawler03.jpg",
                srcWebp: "../assets/images/websites/visuals_crawler/webp/visuals-crawler03.webp",
                loading: "eager"
            },


        ]
    },
    cgvdbenken: {
        title: "Cgvdbenken",
        website: "https://cgvdbenken.net/",
        website02: "cgvdbenken.net/",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/carolin/webp/carolin-von-den-benken_05.webp",
                srcWebp: "../assets/images/websites/carolin/jpeg/carolin-von-den-benken_05.jpg",
                loading: "eager"
            },


        ]
    },

    barred: {
        title: "Barred Berlin",
        website: "https://barred-berlin.ff87c3.eu/",
        website02: "barred-berlin.de/",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/barred_berlin/jpg/barred_berlin04.jpg",
                srcWebp: "../assets/images/websites/barred_berlin/webp/barred_berlin04.webp",

            },
            /*     {
                    key: 2,
                    srcJpeg: "../assets/images/websites/barred_berlin/jpg/barred_berlin04.jpg",
                    srcWebp: "../assets/images/websites/barred_berlin/webp/barred_berlin04.webp",
    
                }, */

        ],

    },
    /* 
        benoit: {
            website: "https://www.benoit-palop.com/",
            website02: "benoit-palop.com/",
            pictures: [
                {
                    key: 1,
                    srcJpeg: "../assets/images/websites/benoit_palop/jpg/benoit-palop_01.jpg",
                    srcWebp: "../assets/images/websites/benoit_palop/webp/benoit-palop_01.webp",
    
                },
                {
                    key: 2,
                    srcJpeg: "../assets/images/websites/benoit_palop/jpg/benoit-palop_01.jpg",
                    srcWebp: "../assets/images/websites/benoit_palop/webp/benoit-palop_01.webp",
    
                },
                {
                    key: 3,
                    srcJpeg: "../assets/images/websites/benoit_palop/jpg/benoit-palop_03.jpg",
                    srcWebp: "../assets/images/websites/benoit_palop/webp/benoit-palop_03.webp",
    
                },
                {
                    key: 4,
                    srcJpeg: "../assets/images/websites/benoit_palop/jpg/benoit-palop_04.jpg",
                    srcWebp: "../assets/images/websites/benoit_palop/webp/benoit-palop_04.webp",
    
                },
            ],
        }, */



    dannysays: {
        title: "Danny says",
        website: "https://dannysays.ff87c3.eu/",
        website02: "dannysays.eu/",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/danny_says/jpg/danny-says_04.jpg",
                srcWebp: "../assets/images/websites/danny_says/webp/danny-says_04.webp",
                loading: "eager"
            },
            /*   {
                  key: 2,
                  srcJpeg: "../assets/images/websites/danny_says/jpg/danny-says_05.jpg",
                  srcWebp: "../assets/images/websites/danny_says/webp/danny-says_05.webp",
                  loading: "eager"
              },
              {
                  key: 3,
                  srcJpeg: "../assets/images/websites/danny_says/jpg/danny-says_06.jpg",
                  srcWebp: "../assets/images/websites/danny_says/webp/danny-says_06.webp",
                  loading: "eager"
              }, */

        ]
    },


    ex: {
        title: "Ex Magazine",
        website: "https://www.exmagazine.fr/",
        website02: "exmagazine.fr/",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/ex_magazine/jpg/ex-magazine_02.jpg",
                srcWebp: "../assets/images/websites/ex_magazine/webp/ex-magazine_02.webp",
                loading: "eager"
            },
            /*      {
                     key: 2,
                     srcJpeg: "../assets/images/websites/ex_magazine/jpg/ex-magazine_02.jpg",
                     srcWebp: "../assets/images/websites/ex_magazine/webp/ex-magazine_02.webp",
                     loading: "eager"
                 },
                 {
                     key: 3,
                     srcJpeg: "../assets/images/websites/ex_magazine/jpg/ex-magazine_03.jpg",
                     srcWebp: "../assets/images/websites/ex_magazine/webp/ex-magazine_03.webp",
                     loading: "eager"
                 },
                 {
                     key: 4,
                     srcJpeg: "../assets/images/websites/ex_magazine/jpg/ex-magazine_04.jpg",
                     srcWebp: "../assets/images/websites/ex_magazine/webp/ex-magazine_04.webp",
                     loading: "eager"
                 }, */

        ]
    },

    flesh: {
        title: "Flesh TV",
        website: "https://www.fleshtv.world/",
        website02: "fleshtv.world/",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/flesh_tv/jpg/flesh_tv_01.jpg",
                srcWebp: "../assets/images/websites/flesh_tv/webp/flesh_tv_01.webp",
                loading: "eager"
            },

        ]
    },

    /*     post: {
            title: "Post distortion",
            website: "https://www.post-distortion.eu/",
            website02: "post-distortion.eu/",
            pictures: [
                {
                    key: 1,
                    srcJpeg: "../assets/images/websites/post_distortion/jpg/post_distortion_05.jpg",
                    srcWebp: "../assets/images/websites/post_distortion/webp/post_distortion_05.webp",
                    loading: "eager"
                },
               
    
            ]
        }, */

    sienna: {
        title: "Sienna Carlisle",
        website: "https://siennacarlisle.com/",
        website02: "siennacarlisle.com/",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/sienna_carlisle/jpeg/sienna_carlisle_01.jpg",
                srcWebp: "../assets/images/websites/sienna_carlisle/webp/sienna_carlisle_01.webp",
                loading: "eager"
            },

        ]
    },


    timothee: {
        title: "Timothée L",
        website: "https://www.timothee-lambrecq.com/",
        website02: "timothee-lambrecq.com/",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/timothee_lambrecq/jpeg/timothee_02.jpg",
                srcWebp: "../assets/images/websites/timothee_lambrecq/webp/timothee_02.webp",
                loading: "eager"
            },
            /*     {
                    key: 2,
                    srcJpeg: "../assets/images/websites/timothee_lambrecq/jpg/timothee_lambrecq_02.jpg",
                    srcWebp: "../assets/images/websites/timothee_lambrecq/webp/timothee_lambrecq_02.webp",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../assets/images/websites/timothee_lambrecq/jpg/timothee_lambrecq_03.jpg",
                    srcWebp: "../assets/images/websites/timothee_lambrecq/webp/timothee_lambrecq_03.webp",
                    loading: "eager"
                },
     */


        ]
    },

    waven3: {
        title: "Waven3",
        website: "https://www.waven3.space/",
        website02: "waven3.space/",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/waven3/jpg/waven3_02.jpg",
                srcWebp: "../assets/images/websites/waven3/webp/waven3_02.webp",
                loading: "eager"
            },
            /*       {
                      key: 2,
                      srcJpeg: "../assets/images/websites/waven3/jpg/waven3_02.jpg",
                      srcWebp: "../assets/images/websites/waven3/webp/waven3_02.webp",
                      loading: "eager"
                  },
                  {
                      key: 3,
                      srcJpeg: "../assets/images/websites/waven3/jpg/waven3_03.jpg",
                      srcWebp: "../assets/images/websites/waven3/webp/waven3_03.webp",
                      loading: "eager"
                  },
                  {
                      key: 4,
                      srcJpeg: "../assets/images/websites/waven3/jpg/waven3_04.jpg",
                      srcWebp: "../assets/images/websites/waven3/webp/waven3_04.webp",
                      loading: "eager"
                  }, */
        ]
    },


    pixel: {
        title: "Pixel Mitosis",
        website: "https://pixel-mitosis.ff87c3.eu/",
        website02: "pixel-mitosis.eu/",
        pictures: [
            {
                key: 1,
                srcJpeg: "../assets/images/websites/pixel_mitosis/jpg/pixel_mitosis_01.jpg",
                srcWebp: "../assets/images/websites/pixel_mitosis/webp/pixel_mitosis_01.webp",
                loading: "eager"
            },
        ]
    },





}

export default imagesWebsite;



