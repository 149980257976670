import React from 'react';
import gsap from "gsap";

export default function Enter() {

    const handleClick = () => {

        //fade out
        //gsap.to('#entrance', { opacity: 0, delay: 1, duration: 1, ease: "power3.inOut", display: "none" })

        //fade in
        gsap.to('main', { opacity: 1, /* delay: 2, duration: 4, */ ease: "power3.inOut", display: "block", overflowY: "auto" })

        /*    gsap.to('#menu', { opacity: 1, delay: 0.3, left: 0, duration: 2, ease: "power3.inOut", display: "block", }) */
        gsap.to("#menu li", {
            delay: 0,
            opacity: 1,
            duration: 0.5, // Duration of the fade effect in seconds
            ease: "power3.inOut",
            stagger: 0.1, // 250ms delay between each `li` fade out
            onComplete: fadeInSections // Callback function after `li` animation completes
        });

        // Fade in the sections
        function fadeInSections() {
            gsap.to("section", {
                opacity: 1,
                duration: 3,
                ease: "power3.inOut",
                stagger: 0.25 // Adjust the stagger value as needed for sections
            });
        }

        gsap.to('canvas', { filter: 'blur(2px)', delay: 2, duration: 4, ease: "power3.inOut", pointerEvents: "none" })

        gsap.to('.enter', { opacity: 0, delay: 0, duration: 1, ease: "power3.inOut", pointerEvents: "none" })

    }

    return (
        <div id="entrance"
            onClick={handleClick}>
            <p className="enter">
                enter
            </p>
        </div>
    );
}