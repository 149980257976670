import React, { useEffect, useState } from 'react'
import imagesWebsite from '../assets/database/images-website.js'

const Sections = () => {

    const [imageDimensions, setImageDimensions] = useState({});

    const handleImageLoad = (websiteKey, index, event) => {
        const { naturalWidth, naturalHeight } = event.target;
        setImageDimensions(prevDimensions => ({
            ...prevDimensions,
            [`${websiteKey}_${index}`]: { width: naturalWidth, height: naturalHeight }
        }));
    };

    useEffect(() => {
        const liItems = document.querySelectorAll('li')

        liItems.forEach(li => {
            li.addEventListener('click', () => {

                liItems.forEach(li => {
                    li.classList.remove('li_selected')
                })

                li.classList.add('li_selected')
            })
        })

        function activateMenuLink(sectionId) {
            document.querySelectorAll('#menu li').forEach((link) => {
                link.classList.toggle('li_selected', link.getAttribute('data-section') === sectionId);
            });

            const allInfo = document.querySelectorAll('.info')
            allInfo.forEach((info) => {
                info.style.display = "none"
            })

            document.querySelector(`section#${sectionId} .info`).style.display = "block"
        }

        // Options for the Intersection Observer
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        // Callback for the Intersection Observer
        function observerCallback(entries) {
            entries.forEach((entry) => {

                // Check if the section is intersecting
                const pictureElement = entry.target.querySelector('picture');

                /*     if (pictureElement) { */
                if (entry.isIntersecting) {
                    // Get the section ID
                    const sectionId = entry.target.getAttribute('data-section-id');

                    // Activate the corresponding menu link
                    activateMenuLink(sectionId);

                    /*      pictureElement.classList.remove('picture-blur');
                         pictureElement.classList.add('non-blurred');
     
                     } else {
                         pictureElement.classList.add('picture-blur');
                         pictureElement.classList.remove('non-blurred'); */
                }
            });
        }

        // Create the Intersection Observer
        const observer = new IntersectionObserver(observerCallback, observerOptions);

        // Observe each section
        document.querySelectorAll('section').forEach((section) => {
            observer.observe(section);
        });

    }, []);

    return (
        <main>
            <div id="sections">
                {Object.keys(imagesWebsite).map((websiteKey) => (
                    <section id={websiteKey} data-section-id={websiteKey} key={websiteKey}>

                        {imagesWebsite[websiteKey].pictures.map((picture, picIndex) => (
                            <a href={imagesWebsite[websiteKey].website} target='_blank' rel="noreferrer" key={`${websiteKey}-${picIndex}`}>
                                <picture>
                                    <source type="image/webp" srcSet={picture.srcWebp}>
                                    </source>
                                    <img src={picture.srcJpeg}
                                        alt={`${websiteKey} website`}
                                        width={imageDimensions[`${websiteKey}_${picIndex}`]?.width || 'auto'}
                                        height={imageDimensions[`${websiteKey}_${picIndex}`]?.height || 'auto'}
                                        onLoad={(event) => handleImageLoad(websiteKey, picIndex, event)} />

                                </picture>
                            </a>
                        ))}

                        <p className="info">
                            <a target="_blank" rel="noreferrer" href={imagesWebsite[websiteKey].website}>{imagesWebsite[websiteKey].website02}</a>
                        </p>

                    </section>
                ))}

                <section id="contact" data-section-id="contact" key="contact">
                    <p className="bio pink-color">Any digital fantasy?
                        Design+development of custom web projects
                        with a focus on interactivity, creative randomization and experimental frontend features. Programmatically made in Berlin. info@ff87c3.eu
                    </p>
                    <p className="info">
                        {/*                         <a href="mailto:info@ff87c3.eu" className="website_url">info@ff87c3.eu</a>
 */}                    </p>
                </section>
            </div>

            <ul id="menu">
                {Object.keys(imagesWebsite).map((websiteKey) => (
                    <li data-section={websiteKey}
                        key={websiteKey}
                    >
                        <a href={`#${websiteKey}`}>{imagesWebsite[websiteKey].title}</a>
                    </li>
                ))}

                <li data-section="contact" key="854dfgfg"> <a href="#contact">contact</a></li>
            </ul>
        </main>
    )
}

export default Sections


